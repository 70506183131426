import React, { useContext, lazy } from "react";
import * as _ from "lodash";
import {Route, Switch} from "react-router-dom";
import Login from "pages/login";
import {AuthContext} from "services/providers/auth-provider";
import PrivateRoute from "./private-route";

export const RouterConfig = (props) => {
  const {routes} = props
  const {isAuthenticated, accessRights} = useContext(AuthContext);

  const getAppInformations = (app) => {
    let appInfos = undefined
    if (isAuthenticated) {
      appInfos = {...app}
      appInfos.sub_apps = _.filter(app.sub_apps, a => a.permissions_min >= accessRights)
    }
    return appInfos
  }

  const getComponent = (app) => {
    switch (app) {
      case "home":
        return lazy(() => import('pages/home'));
      case "consommation_mobile":
        return lazy(() => import('pages/applications/consommation-mobile'));
      case "collect_home":
        return lazy(() => import('pages/applications/collecte-technique/collect-home'));
      case "collect_fixe":
        return lazy(() => import('pages/applications/collecte-technique/collect-fixe'));
      case "collect_add_edit_fixe":
        return lazy(() => import('pages/applications/collecte-technique/collect-add-edit'));
        // return lazy(() => import('pages/applications/collecte-technique/test_components'));
      case "collect_user_directory":
        return lazy(() => import('pages/applications/collecte-technique/collect-user-directory'));
      case "collect_add_edit_user_directory":
        return lazy(() => import('pages/applications/collecte-technique/collect-add-edit'));
      case "collect_list":
        return lazy(() => import('pages/applications/collecte-technique/collect-list'));
      case "collect_print":
        return lazy(() => import('pages/applications/collecte-technique/collect-print'));
        // return lazy(() => import('pages/applications/collecte-technique/collect-print-2'));
      case "import_mobile":
        return lazy(() => import('pages/applications/mobile/mobile-home'));
      case "lines_creation":
        return lazy(() => import('pages/applications/mobile/mobile'));
      case "various":
        return lazy(() => import('pages/applications/mobile/mobile-various'));
      case "lines_edit":
        return lazy(() => import('pages/applications/mobile/mobile-phoneplan'));
      case "users_creation":
        return lazy(() => import('pages/applications/mobile/mobile-createusers'));
      case "users_update":
        return lazy(() => import('pages/applications/mobile/mobile-updateusers'));
      case "delete_update":
        return lazy(() => import('pages/applications/mobile/mobile-deleteusers'));
      case "line_transfer":
        return lazy(() => import('pages/applications/mobile/mobile-transferlines'));
      case "emm_store":
        return React.lazy(() => import('pages/applications/emm-store'))
      case "tableau_deploiement":
        return React.lazy(() => import('pages/applications/tableau-deploiement'))
      case "groupsprtg":
        return lazy(() => import('pages/applications/prtg/groupsprtg'));
      case "prtg":
        return lazy(() => import('pages/applications/prtg'));
      case "pvrecette":
        return React.lazy(() => import('pages/applications/pvrecette'))
      case "qrcodeswifi":
        return React.lazy(() => import('pages/applications/qrcodes-wifi'))
      case "qrcodestiny":
        return React.lazy(() => import('pages/applications/qrcodes-tiny'))
      case "tinyconsoles":
        return React.lazy(() => import('pages/applications/qrcodes-tiny/qrcodestiny_consoles'))
      case "qrcodestinylist":
        return React.lazy(() => import('pages/applications/qrcodes-tiny/qrcodestiny_list'))
      case "tinydevicein":
        return React.lazy(() => import('pages/applications/qrcodes-tiny/tiny_device_in'))
      case "pvrecette_projectmanager":
        return React.lazy(() => import('pages/applications/pvrecette/pvrecette_projectmanager'))
      case "pvrecette_equipments":
        return React.lazy(() => import('pages/applications/pvrecette/pvrecette_equipments'))
      case "eligibility":
        return React.lazy(() => import('pages/applications/eligibility'))
      case "partagesecurise":
        return React.lazy(() => import('pages/applications/partagesecurise'))
      case "add_userapi":
        return React.lazy(() => import('pages/applications/add_userapi/index'))
      case "management":
        return React.lazy(() => import('pages/administrations/management/management-home'))
      case "mondayworkspaces":
        return React.lazy(() => import('pages/administrations/management/mondayworkspaces'))
      case "cronscripts":
        return React.lazy(() => import('pages/administrations/management/cronscripts'))
      case "admin_users":
        return lazy(() => import('pages/administrations/administration-utilisateurs'));
      case "role_managment":
        return lazy(() => import('pages/administrations/administration-utilisateurs').then(module => (
          {default: module.AdminRoles})));
      case "permissions_managment":
        return lazy(() => import('pages/administrations/administration-utilisateurs').then(module => (
          {default: module.AdminPermissions})));
      default:
        return lazy(() => import('./not-found'));
    }
  }

  const buildRoutes = () => {
    let ret = []
    routes.forEach(r => {
      if (r.application !== "home") {
        r.sub_apps.forEach(s => {
          if (s.application !== "home" && s.front_url !== r.front_url) {
            ret.push({
              path: s.front_url,
              application: getAppInformations(r),
              subapplication: s,
              permissions: s.permissions_min
            })
          }
        })
      }
      ret.push({
        path: r.front_url,
        permissions: r.permissions_min,
        application: getAppInformations(r)
      })
    })
    return ret
  }

  return (
    <Switch>
      <Route exact path={"/login"} component={Login}/>
      {buildRoutes().map((p, i) => (
        <PrivateRoute
          key={i}
          exact
          children={(props) => {
            const Component = getComponent(p.subapplication ? p.subapplication.application : p.application.application)
            return <Component application={p.application} subapplication={p.subapplication} {...props} />
          }}
          {...p}
        />
      ))}
      {/* 2 next declarations must be at the end for render the other */}
      <Route path={"/no-access"} children={(props) => {
        const Component = lazy(() => import('./no-access'))
        return <Component {...props} />
      }}/>
      <Route path={"*"} children={(props) => {
        const Component = lazy(() => import('./not-found'))
        return <Component {...props} />
      }}/>
    </Switch>
  );
}
