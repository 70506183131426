import {createTheme, responsiveFontSizes} from "@material-ui/core";

export let light = createTheme({
  palette: {
    type: "light",
    // primary: {
    //   main: '#33A3FF',
    //   contrastText: '#ffffff'
    // },
  },
});

light = responsiveFontSizes(light);
